html,body {
    background-color: black;
}

body {
    margin: 0;
    padding: 0;
    font-family: "Oswald", sans-serif !important;
    background-color: transparent;
}

#root {
    /* background: url("../images/background.png") no-repeat center center fixed; */
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.btn-logout {
    background-color: transparent;
    background-image: none;
    border-color: #0000ff;
    color: #0000ff;
    font-family: "Oswald", sans-serif;
    margin-top: 1em;
    margin-right: 0.5em;
    margin-left: 0.5em;
}

.headerImage{
    display: block;
    max-width:100%;
    max-height:200px;
    width: auto;
    height: auto;
}

.headerBody{
    width: 100%;
    font-family: "Oswald", sans-serif;
    text-align: center;
    font-size: 20px;
}

.headerText{
    text-align: center;
    width: 100%;
    font-family: "Oswald", sans-serif;
    font-size: 45px;
}

.vertical_spacer{
    width: 100%;
    height:20px;
}

.itemPopUpTitle{
    font-family: "Oswald", sans-serif;
    font-size: 30px;
    font-weight: 700;
}

.popUpHeader {
    font-family: "Oswald", sans-serif;
    font-size: 16px;
    text-align: center;
    font-weight: 700;
}

.popUpInput {
    border: black 1px solid;
    width: 100%;
    font-size: 20px;
    padding:5px 0;
    font-family: "Oswald", sans-serif;
    margin: 15px 0;
    border-radius: 10px;
}

.popUpButton{
    text-align: center;
    font-size: 20px;
    padding: 0 40px;
    font-family: "Oswald", sans-serif;
    border-radius: 5px;
    min-width: 30px;
    font-weight: 700;
}

.fullscreen{
    position:absolute;
    left:0;
    top:0;
    bottom: 0;
    right:0;
    width:100%;
    height:100%;
    background-color: black;
    text-align: center;
}

.qrScanner{
    min-width:280px;
    background-color: black;
}

.aboveQrcodeText{
    color:white;
    font-family: "Oswald", sans-serif;
    font-weight: 600;
}

/* Flexbox version */
::-webkit-scrollbar {
    width: 0;  /* remove scrollbar space */
    background: transparent;  /* optional: just make scrollbar invisible */
}
